import { createContext, useEffect, useState } from 'react';
import AuthAxios from '../axios';
import { SubscriptionInfo, SubscriptionUserInfo, User } from '../types';
import { getItem, removeItem, setItem, USERINFO, USERTOKEN } from '../utils/localStorage';
import { useNavigate } from 'react-router-dom';

type AuthState = {
  isAuthentication: boolean;
  user: User | null;
};
type AuthContextType = AuthState & {
  setLoginSucssess: (user: User) => void;
  setLogout: () => void;
  handleSubscribtion: () => void;
  handeCancleSubscribtion: () => void;
  getAuth: () => Promise<void>;
};
export const AuthStateContext = createContext<AuthContextType>({
  isAuthentication: false,
  user: null,
  setLoginSucssess: () => {}, // 기본 구현 제공
  setLogout: () => {},
  handleSubscribtion: () => {},
  handeCancleSubscribtion: () => {},
  getAuth: async () => {},
});

export const AuthContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [authState, setAuthState] = useState<AuthState>({
    isAuthentication: getItem(USERTOKEN) ? true : false,
    user: getItem(USERINFO),
  });

  const navigate = useNavigate();


  const setLogout = () => {
    removeItem(USERTOKEN);
    removeItem(USERINFO);
    setAuthState({ isAuthentication: false, user: null });
  };

  const setLoginSucssess = (user: User) => {
    setAuthState({ isAuthentication: true, user: user });
    setItem(USERINFO, user);
  };

  /** 구독 하기 */
  const handleSubscribtion = () => {
    if (authState.user) {
      setAuthState({
        isAuthentication: true,
        user: { ...authState.user, currentSubscription: true },
      });
      setItem(USERINFO, { ...authState.user, currentSubscription: true });
    }
  };

  /** 구독 취소하기. */
  const handeCancleSubscribtion = () => {
    if (authState.user) {
      setAuthState({
        isAuthentication: true,
        user: { ...authState.user, currentSubscription: false },
      });
      setItem(USERINFO, { ...authState.user, currentSubscription: false });
    }
  };

  const getAuth = async () => {
    if (authState.isAuthentication) {
      await AuthAxios.get<{ subscription: SubscriptionInfo, userInfo: SubscriptionUserInfo }>(`/users/current-subsription`)
        .then((resolve) => {
          console.log('resolve:',resolve)
          if (resolve.data.subscription.endDate) {
            if (new Date() < new Date(resolve.data.subscription.endDate)) {
              setAuthState({
                isAuthentication: getItem(USERTOKEN) ? true : false,
                user: { ...getItem(USERINFO), currentSubscription: true },
              });
            } else {
              setAuthState({
                isAuthentication: getItem(USERTOKEN) ? true : false,
                user: { ...getItem(USERINFO), currentSubscription: false },
              });
            }
          } else {
            setAuthState({
              isAuthentication: getItem(USERTOKEN) ? true : false,
              user: { ...getItem(USERINFO), currentSubscription: false },
            });
          }
          if(!resolve.data.userInfo.termsAgreed) {
            navigate('/auth/agrement', { replace: true})
            console.log('Did not agree to the terms.')
          }
        })
        .catch((error) => {
          setAuthState({
            isAuthentication: getItem(USERTOKEN) ? true : false,
            user: { ...getItem(USERINFO), currentSubscription: false },
          });
        });
    } else {
      setLogout();
    }
  }

  useEffect(() => {
    getAuth();
  }, [authState.isAuthentication]);

  const value = {
    ...authState,

    setLoginSucssess,
    setLogout,
    handleSubscribtion,
    handeCancleSubscribtion,
    getAuth,
  };

  return <AuthStateContext.Provider value={value}>{children}</AuthStateContext.Provider>;
};

// export const useAuthState = () => {
//   const authState = useContext(AuthStateContext);
//   if (!authState) throw new Error("AuthProvider not found");
//   return authState;
// };
